import { DisplayStyle } from '@components/activity/src/components/RewardGroup/types';

import { ActivityTypeEnum } from './enum';

export interface BaseUser {
  uid: number;
  duid: number;
  suid: string;
  suidLv: number;
  nickname: string;
  avatarurl: string;
}

export interface SimpleUser extends BaseUser {
  dgid: number;
  sgid: string;
  sgidLv: number;
  gid: number;
}

export interface LoadMoreRes<T> {
  limitCount?: number;
  hasMore: boolean;
  scroll?: string;
  list: T[];
}

export interface UserRank extends BaseUser {
  val: number;
  gender: number;
  isMine: boolean;
  gid?: number;
  svip?: number;
}

export interface RoomRank {
  val: number;
  dgid: number;
  sgid: string;
  gid: number;
  name: string;
  isMine: boolean;
  logourl: string;
}

export interface RankingApiParams {
  activitiesId: number;
  ext?: string;
  partition?: number;
}

export type RoomRankingRes = LoadMoreRes<RoomRank>;
export type UserRankingRes = LoadMoreRes<UserRank>;

export interface SimpleUserApiParams {
  uid?: number;
  gid?: number;
  paramsUid?: string;
  paramsGid?: string;
}

export interface SimpleUserRes {
  user: SimpleUser;
}

export interface IUserInfoRes extends BaseUser {
  gender: number;
  countryId: number;
  region: Regions;
}

export enum Regions {
  AR = 'AR',
  TR = 'TR',
  EN = 'EN',
  ES = 'ES',
}

export interface FullApiRes<T> {
  code: number;
  msg: string;
  res: T;
}

export interface RankBaseParams {
  activitiesId: number;
}

export interface GetUserRankRes {
  country: UserRank[];
  world: UserRank[];
  countryLimit: number;
  worldLimit: number;
  limitCount?: number;
  list: UserRank[];
}

export interface GetRoomRankRes {
  country: RoomRank[];
  world: RoomRank[];
  countryLimit: number;
  worldLimit: number;
  limitCount?: number;
  list: RoomRank[];
}
export interface GetActConfigRes {
  id: number;
  startTime: number;
  endTime: number;
  isPartition: boolean;
  isNationalDay: boolean;
  regionList: string[];
  giftInfoList?: GetActConfigGiftInfoListItem[];
  extConfig?: string;
  subActivitiesTypes?: number[];
  // 是否在白名单内  无登陆状态下也是 true
  isWhite?: boolean;
}

export interface GetActConfigGiftInfoListItem {
  giftId: number;
  cover: string;
  worth: number;
}

/* --------------- 活动奖励组ApiRes Start -------------------*/

export interface FetchRewardConfigV3Res {
  userSendGiftRank?: FetchRewardConfigV3ResUserConfig;
  userReceiveGiftRank?: FetchRewardConfigV3ResUserConfig;
  userRechargeRank?: FetchRewardConfigV3ResUserConfig;
  ordinaryRoomRank?: FetchRewardConfigV3ResRoomConfig;
}

export interface FetchRewardConfigV3ResUserConfig {
  userConfig: ActivitiesReward[];
}
export interface FetchRewardConfigV3ResRoomConfig {
  roomConfig: ActivitiesReward[];
}

export interface ActivitiesReward {
  id: number;
  name: string;
  sort: number;
  displayStyle: DisplayStyle;
  nameI18n: NameI18N;
  activityGiftConfigGroupGiftList: RewardGift[];
  displayStyleExtJson?: string;
}

export interface DisplayStyleExtJsonItem {
  size: number;
  keyI18n: string;
  nameI18n: Partial<NameI18N>;
}

export interface RewardGift {
  id: number;
  name: string;
  expireType: number;
  cover: string;
  sort: number;
  nameI18n: NameI18N;
}

export interface NameI18N {
  ar: string;
  en: string;
  tr: string;
  zh: string;
  es: string;
  pt: string;
  bn: string;
  hi: string;
  ur: string;
}
/* --------------- 奖励组ApiRes End -------------------*/

/* --------------- 任务系统 Start -------------------*/
export interface PrizeInfoRes {
  taskList: TaskList[];
}

export interface TaskList {
  actTaskInfo: ActTaskInfo[];
  myScore: number;
  // 1-送礼任务 2-收礼任务 3-充值任务 4-在麦任务
  taskType: number;
}

export interface ActTaskInfo {
  taskPrizeConfigId: number;
  taskPrizeTitle: string;
  taskPrizeContent: string;
  taskPrizeList: TaskPrizeList[];
  receiveState: number;
  needScore: number;
}

export interface TaskPrizeList {
  prizeId: number;
  itemType: number;
  itemId: number;
  itemCount: number;
  itemDays: number;
  itemIcon: string;
  customIcon: string;
  worth: number;
  sort: number;
  content: string;
}

export interface APIGetGiftParams extends RankBaseParams {
  taskPrizeConfigId: number;
}

export interface GetTaskPrizeRecordsParams extends RankBaseParams {
  scroll?: string; // 分页id
  limitCount: number; // 取值范围为[0,20],否则默认为10
}

export interface GetTaskPrizeRecordsResItem {
  id: number;
  prizeId: number;
  worth: number;
  itemCount: number;
  customIcon?: string;
  itemIcon: string;
  content: string;
  createTime: number;
}

export type GetTaskPrizeRecordsRes = LoadMoreRes<GetTaskPrizeRecordsResItem>;

// 任务系统二期

export interface GetTaskInfoV2Res {
  // 阶梯任务返回
  taskInfo?: TaskItem[];

  // 积分任务返回
  boxInfo?: TaskItem[];
  dailyTask?: TaskItem[];
  cumulativeTask?: TaskItem[];
  // 当前用户 svip等级
  userSvip?: number;
}

export interface OnTaskInfoReceiveV2Params extends RankBaseParams {
  id: number;
}

/**
 * 完整的任务模型， 涵盖  阶梯任务, 宝箱任务，每日任务，累计任务
 */
/**
 * TaskItem
 */
export interface TaskItem {
  /**
   * 主活动ID
   */
  actId: number;
  /**
   * 奖励的积分，每日任务用到
   */
  awardScore: number;
  id: number;
  /**
   * 任务完成进度
   */
  myScore: number;
  /**
   * 任务完成门槛
   */
  needScore: number;
  /**
   * 奖品信息
   */
  prizeInfos?: TaskPrizeList[];
  /**
   * 领取状态， 0-未完成 1-已完成未领取 2-已完成已领取 3-已结束
   */
  receiveState: number;
  /**
   * 任务子类型，
   */
  subType: number;
  /**
   * 任务的描述
   */
  taskDesc: string;
  /**
   * 任务Icon
   */
  taskIcon: string;
  /**
   * 活动玩法类型，对应主活动的ActivityType：
   */
  taskType: number;
  /**
   * 领取状态 1-自动领取 2-手动领取
   */
  receiveType: number;
  /**
   * 0 - 10
   */
  svipLimitRule?: number;
  refreshCycle: number;
}

/* --------------- 任务系统 End -------------------*/

export interface GetJackpotPrizeInfoParams {
  genericNumbers: number[];
}

export type GetJackpotPrizeInfoRes = {
  [key in number]?: JackpotPrizeInfoItem[];
};

export interface JackpotPrizeInfoItem {
  content: string;
  customIcon: string;
  itemCount: number;
  itemDays: number;
  itemIcon: string;
  itemId: number;
  itemType: number;
  prizeId: number;
  sort: number;
  worth: number;
}

// v3 榜单接口
export type GetRankingRes = GetUserRankRes | GetRoomRankRes;
export interface GetRankingParams extends RankBaseParams {
  activityType: keyof typeof ActivityTypeEnum;
}

/* --------------- 模板化配置 Start -------------------*/
export type IExtConfigType = Partial<{
  web: {};
  nationalDayWeb: {};
  templateConfig: TemplateConfig;
}>;

export interface TemplateConfig {
  templateType: 'A' | 'B' | string;
  templateName: string;
  templateTheme: string;
  mergeRankActivityType: boolean;
  mergeTaskActivityType: boolean;
  dataConfig?: TemplateConfigDataConfig;
  themeConfig?: TemplateConfigThemeConfig;
  rank?: TemplateConfigRank;
}

export interface TemplateConfigRank {
  userRechargeHiddenScore?: boolean;
}

interface TemplateConfigThemeConfig {
  body?: TemplateConfigThemeConfigBody;
  task?: TemplateConfigThemeConfigTask;
  intro?: TemplateConfigThemeConfigIntro;
  navToggleCard?: TemplateConfigThemeConfigNavToggleCard;
  reward?: TemplateConfigThemeConfigReward;
}

interface TemplateConfigThemeConfigTask {
  dailyTaskItemBg: string;
  stepsTaskItemProgressBg: string;
}

interface TemplateConfigThemeConfigBody {
  background: string;
  mainColor: string;
}

interface TemplateConfigThemeConfigIntro {
  countColor: string;
}

interface TemplateConfigDataConfig {
  banner?: TemplateConfigDataConfigBanner;
  task?: TemplateConfigDataConfigTask;
}

interface TemplateConfigThemeConfigNavToggleCard {
  color: string;
}

interface TemplateConfigThemeConfigReward {
  titleColor: string;
}

interface TemplateConfigDataConfigTask {
  scoreIntroImage: string;
  scoreIcon: string;
}

interface TemplateConfigDataConfigBanner {
  title?: TemplateConfigDataConfigBannerImage;
  cover: string;
  webp: string;
}

interface TemplateConfigDataConfigBannerImage {
  AR: string;
  TR: string;
  EN: string;
  ES: string;
}
/* --------------- 模板化配置 End -------------------*/

export interface GetPrizePackageParams extends RankBaseParams {
  itemType: number;
}
export interface GetPrizePackageRes {
  packageInfos: PackageInfo[];
}

export interface PackageInfo {
  /**
   * 礼包Id
   */
  id: number;
  /**
   * 解锁礼包需要的积分
   */
  needScore: number;
  /**
   * 领取状态 0-未完成 2-已完成已领取
   */
  receiveState: number;
  prizeInfos?: TaskPrizeList[];
  /**
   * 礼包剩余数量
   */
  balanceCount: number;
}
