import GaWrapperPage from '@components/module/src/components/GaWrapperPage';
import NotFound from '@components/module/src/components/NotFound';
import { IRouterConfig } from 'ice';

// import { renderNotFound, isInIcestark } from '@ice/stark-app';
import BasicLayout from '@/layouts/BasicLayout';
import CollectionInfo from '@/pages/CollectionInfo';
import Contact from '@/pages/Contact';
import Home from '@/pages/Home';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import UserAgreement from '@/pages/UserAgreement';

const routerConfig: IRouterConfig[] = [
  {
    path: '/',
    component: BasicLayout,
    children: [
      {
        path: '/',
        // @ts-ignore
        exact: true,
        component: Home,
        wrappers: [GaWrapperPage],
      },
      {
        path: '/collection-info',
        // @ts-ignore
        exact: true,
        component: CollectionInfo,
        wrappers: [GaWrapperPage],
      },
      {
        path: '/user-agreement',
        // @ts-ignore
        exact: true,
        component: UserAgreement,
        wrappers: [GaWrapperPage],
      },
      {
        path: '/privacy-policy',
        // @ts-ignore
        exact: true,
        component: PrivacyPolicy,
        wrappers: [GaWrapperPage],
      },
      {
        path: '/contact',
        // @ts-ignore
        exact: true,
        component: Contact,
        wrappers: [GaWrapperPage],
      },
      {
        // 微应用独立运行 404 路由渲染 NotFound 组件
        // @ts-ignore
        // component: isInIcestark() ? () => renderNotFound() : NotFound,
        component: NotFound,
      },
    ],
  },
];

export default routerConfig;
