/*
 * @Description: 隐私政策
 * @Author: liaoxingfeng@iyinguo.com
 */

import { useStarkTranslation } from '@falla/hooks/src';
import useDocumentTitle from '@falla/hooks/src/useDocumentTitle';
import React from 'react';

import styles from './index.module.less';

interface IProps {}

const PrivacyPolicy: React.FC<IProps> = () => {
  const { t } = useStarkTranslation();
  useDocumentTitle(t('contactus', 'Contact Us'), { restoreOnUnmount: true });

  return (
    <div className={styles.privacyPolicy}>
      <div className={styles.wrapper}>
        <div className={styles.title}>{t('contactus', 'Contact Us')}</div>
        <div
          className={styles.content}
          dangerouslySetInnerHTML={{
            __html: t(
              'contactusContent',
              // eslint-disable-next-line max-len
              'Email：thejoymiapp@gmail.com\u003cbr/\u003eTel: +86 147 9765 0006',
            ),
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(PrivacyPolicy);
