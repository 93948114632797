/**
 * number-util.ts
 *
 * @author fukui
 */
export const parse = function (val = 0, digits = 2): number {
  val = val || 0;
  digits = digits === 0 ? 0 : digits || 2;
  return isFinite(val) ? Math.round(val * Math.pow(10, digits)) / Math.pow(10, digits) : 0;
};

/**
 * 给数字添加分隔符，默认千位分割
 * @param num
 * @param length
 * @param separator
 * @return {string}
 */
export const separator = function (num = 0, length = 3, separator = ',', decimalSeparator = '.') {
  // let numberStr = String(num.toFixed());
  const numberStr = String(num);
  let intNum = numberStr?.split('.')?.[0]; // 整数部分
  const decimalNum = numberStr?.split('.')?.[1]; // 小数部分
  const regex = new RegExp(`(\\d+)(\\d{${length}})`);
  while (regex.test(intNum)) {
    intNum = intNum.replace(regex, `$1${separator}$2`);
  }

  return `${intNum}${decimalNum ? `${decimalSeparator}${decimalNum}` : ''}`;
};
