/**
 * config
 *
 * @author fukui
 */
export type APP_MODE_VALUE = 'hk' | 'pre' | 'prod';

enum APP_MODE_ENUM {
  hk = 'hk',
  pre = 'pre',
  prod = 'prod',
}

const APP_MODE = (process.env.APP_MODE in APP_MODE_ENUM ? process.env.APP_MODE : APP_MODE_ENUM.hk) as APP_MODE_VALUE;

declare interface IConfigCommon {
  APP_MODE: APP_MODE_VALUE;
  isDev: boolean;
  isHostProd: boolean;
}
export type IConfig = typeof allConfig.default & IConfigCommon;

const allConfig = {
  default: {
    // 默认配置
    proxyBaseUrl: {
      mock: 'https://mock.apifox.com/m1/3255115-0-default',
      hk: '/proxy-apihk',
      sz: '/proxy-apisz2',
      pre: '/proxy-api-san',
      prod: '/proxy-api',
    },
    baseUrl: {
      mock: 'https://testapi.joymi.live/',
      hk: 'https://testapi.joymi.live/',
      pre: 'https://testapi.joymi.live/',
      prod: 'https://api.joymi.live',
    },
    rongCloud: {
      appKey: 'mgb7ka1nme63g',
    },
    sentryDsn: 'https://f4e08345dff24223868e7d0056651441@o320593.ingest.sentry.io/5885377',
    // https://firebase.google.com/docs/web/setup?authuser=0&sdk_version=v8#add-sdks-initialize
    firebaseConfig: {
      apiKey: 'AIzaSyBEz21ArWHK_3BpVJ4SzuhMvJhRz6FS3LY',
      authDomain: 'falla-web-test.firebaseapp.com',
      projectId: 'falla-web-test',
      storageBucket: 'falla-web-test.appspot.com',
      messagingSenderId: '870350495142',
      appId: '1:870350495142:web:4c7ad67d8492d300cd4367',
      measurementId: 'G-SP9R8G7DYS',
    },
    gaTrackingCode: 'G-SP9R8G7DYS',
    thinkDataAppId: '5f9cde7b1e9648e39c5d3f74aea79932',
  },
  hk: {},
  sz: {},
  pre: {},
  prod: {
    rongCloud: {
      appKey: '8w7jv4qb8zxfy',
    },
    sentryDsn: 'https://c49535d304334af6a2b88e6940393b79@o320593.ingest.sentry.io/5884301',
    firebaseConfig: {
      apiKey: 'AIzaSyDtKIhU2l3TNLh7kiDMHgZdvTPMk6p2LDM',
      authDomain: 'falla-web.firebaseapp.com',
      projectId: 'falla-web',
      storageBucket: 'falla-web.appspot.com',
      messagingSenderId: '1085163611848',
      appId: '1:1085163611848:web:aa1380dfd62309452f67fd',
      measurementId: 'G-4GNMJ474RB',
    },
    gaTrackingCode: 'G-4GNMJ474RB',
    thinkDataAppId: '2b22f442645948b69eccf9ac67f83b83',
  },
};

export const iConfig: IConfig = {
  ...allConfig.default,
  ...(allConfig[APP_MODE] || {}),
  APP_MODE,
  isDev: process.env.NODE_ENV !== 'production',
  isHostProd: APP_MODE === 'prod',
};

export default iConfig as IConfig;
