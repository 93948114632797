export const LANGTYPE_MAPPING = {
  en: 'English',
  tr: 'Türkçe',
};

export const APP_LINK = {
  appStore: 'https://itunes.apple.com/app/id6474529456',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.joymi.seven',
  androidAPK: 'https://www.joymi.live/apk/JoyMi-official.apk',
};
